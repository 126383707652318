<template>
    <v-card class="cont-table">
    <v-card-title>
      <h3>Domain Level Data</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="dataSort"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
      :items-per-page="-1"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td> {{ 'comparison' }} </td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('backlinks', index)">
              {{ Number(comp.backlinks).toFixed(0) | formatNumber }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('text', index)">
              {{ Number(comp.text).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('image', index)">
              {{ Number(comp.image).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('ugc', index)">
              {{ Number(comp.ugc).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('sponsored', index)">
              {{ Number(comp.sponsored).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('redirect', index)">
              {{ Number(comp.redirect).toFixed(0) }}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>Averages</td>
          <td>{{ Number(averages.backlinks).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.text).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.image).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.ugc).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.sponsored).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.redirect).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-checkbox v-model="selected" :ripple="false" :value="item" hide-details />
          </td>
          <td>
          {{ Number(item.rank).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td>
            <div class="col-domain">
              <a :href="item.url" :class="darkTheme ? 'url-data-light' : 'url-data-dark'" target="_blank">{{ item.url }}</a>
            </div>
          </td>
          <td :style="getClassColor(item.backlinks_sort, item)">
            {{ Number(item.backlinks).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.text_sort, item)">
            {{ Number(item.text).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.image_sort, item)">
            {{ Number(item.image).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.ugc_sort, item)">
            {{ Number(item.ugc).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.sponsored_sort, item)">
            {{ Number(item.sponsored).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.redirect_sort, item)">
            {{ Number(item.redirect).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          </tr>
      </template>

    </v-data-table>
  </v-card>

</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      headers: [
        { text: 'Rank', value: 'rank', sortable: true },
        { text: 'URL', value: 'url', sortable: true, width: 350 },
        { text: 'Backlinks', value: 'backlinks', sortable: true },
        { text: 'Text', value: 'text', sortable: true },
        { text: 'Image', value: 'image', sortable: true },
        { text: 'Ugc', value: 'ugc', sortable: true },
        { text: 'Sponsored', value: 'sponsored', sortable: true },
        { text: 'Redirect', value: 'redirect', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.getAverages()
      },
    },
  },
  computed: {
    dataSort() {
      const arrayColumn = (arr, n) => arr.map(x => Number(x[n]))
      const sortAsc = (a, b) => a - b
      const sortDesc = (a, b) => b - a

      const rank = arrayColumn(this.items, 'rank').sort(sortDesc)
      const backlinks = arrayColumn(this.items, 'backlinks').sort(sortAsc)
      const text = arrayColumn(this.items, 'text').sort(sortAsc)
      const image = arrayColumn(this.items, 'image').sort(sortAsc)
      const ugc = arrayColumn(this.items, 'ugc').sort(sortAsc)
      const sponsored = arrayColumn(this.items, 'sponsored').sort(sortAsc)
      const redirect = arrayColumn(this.items, 'redirect').sort(sortAsc)

      return this.items.map((row, i) => {
        row.rank_sort = rank.indexOf(row.rank) + 1
        row.backlinks_sort = backlinks.indexOf(row.backlinks) + 1
        row.text_sort = text.indexOf(row.text) + 1
        row.image_sort = image.indexOf(row.image) + 1
        row.ugc_sort = ugc.indexOf(row.ugc) + 1
        row.sponsored_sort = sponsored.indexOf(row.sponsored) + 1
        row.redirect_sort = redirect.indexOf(row.redirect) + 1
        return row
      })
    },
    darkTheme() {
      return this.$vuetify.theme.isDark;
    }
  },
  methods: {
    getAverages() {
      let totals = this.averages
      const size = this.selected.length

      for (const prop_name in this.averages) {
        totals[prop_name] = 0
      }

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = totals[prop_name] / size
      }

      this.averages = totals
    },
    getTextClass(name = '', index = 0, greater = false) {
      if (greater === false) {
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      } else {
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      }
    },
    getClassColor(value, item) {
      if (item) {
        if (this.selected.indexOf(item) < 0) {
          return null
        }
      }
      if (value) {
        return `background: rgb(52, 144, 220, ${value / 10})`
      }
      return null
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 60px 0px 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
.url-data-light{
  color : var(--primary-shade--dark);
  text-decoration: none;
}
.url-data-dark{
  color : var(--primary-shade--light);
  text-decoration: none;
}
</style>
