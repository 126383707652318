<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="767">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on"> Export Data </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Export Tables </v-card-title>

        <v-card-text>
          <span>Select a format: </span>
          <v-radio-group v-model="format" row style="display: flex; gap: 40px !important">
            <v-radio label="CSV" value="csv"></v-radio>
            <v-radio label="XLSX" value="xlsx"></v-radio>
            <v-radio label="XLS" value="xls"></v-radio>
            <v-radio label="ODS" value="ods"></v-radio>
          </v-radio-group>

          <div style="margin-bottom: 20px">
            <label>Select a table: </label>
          </div>

          <div style="margin: 20px 0px 0px 0px">
            <span>Download All</span>
            <v-divider></v-divider>
          </div>

          <div style="margin: 0px 0px 0px 0px">
            <div class="format-row">
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('full_report_all_files')"
                :disabled="loading.full_report_all_files"
              >
                <template v-if="!loading.full_report_all_files"
                  >Download All <v-icon dark right> mdi-export-variant</v-icon></template
                >
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
            </div>
          </div>

          <div style="margin: 20px 0px 0px 0px">
            <span>SERP Tables</span>
            <v-divider></v-divider>
            <div class="format-row">
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('page_metrics')"
                :disabled="loading.page_metrics"
              >
                <template v-if="!loading.page_metrics"
                  >Page Metrics <v-icon dark right> mdi-export-variant</v-icon></template
                >
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('domain_link_profile')"
                :disabled="loading.domain_link_profile"
              >
                <template v-if="!loading.domain_link_profile">
                  Domain link profile <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('url_link_profile')"
                :disabled="loading.url_link_profile"
              >
                <template v-if="!loading.url_link_profile">
                  URL link profile <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('backlinks_seen')"
                :disabled="loading.backlinks_seen"
              >
                <template v-if="!loading.backlinks_seen">
                  Backlinks Seen <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('domain_level_data')"
                :disabled="loading.domain_level_data"
              >
                <template v-if="!loading.domain_level_data">
                  Domain Level Data <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('page_level_data')"
                :disabled="loading.page_level_data"
              >
                <template v-if="!loading.page_level_data">
                  Page Level Data <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('link_gaps_analysis')"
                :disabled="loading.link_gaps_analysis"
              >
                <template v-if="!loading.link_gaps_analysis">
                  Link Gaps Analysis <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('anchors_aggregate_text')"
                :disabled="loading.anchors_aggregate_text"
              >
                <template v-if="!loading.anchors_aggregate_text">
                  Anchors Aggregate Text <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
              <v-btn
                class="btn"
                color="primary"
                @click="downloadReport('speed_web_vitals')"
                :disabled="loading.speed_web_vitals"
              >
                <template v-if="!loading.speed_web_vitals">
                  Speed and Core Web Vitals <v-icon dark right> mdi-export-variant</v-icon>
                </template>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-btn>
            </div>
          </div>

          <span>Semantic Tables</span>
          <v-divider></v-divider>
          <div class="format-row">
            <v-btn class="btn" color="primary" @click="downloadReport('top_terms')" :disabled="loading.top_terms">
              <template v-if="!loading.top_terms">
                Top terms among top 10 rankings <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn
              class="btn"
              color="primary"
              @click="downloadReport('how_many_times')"
              :disabled="loading.how_many_times"
            >
              <template v-if="!loading.how_many_times">
                How Many Times It's Used <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn class="btn" color="primary" @click="downloadReport('titles')" :disabled="loading.titles">
              <template v-if="!loading.titles"> Titles <v-icon dark right> mdi-export-variant</v-icon> </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn
              class="btn"
              color="primary"
              @click="downloadReport('heading_analysis')"
              :disabled="loading.heading_analysis"
            >
              <template v-if="!loading.heading_analysis">
                Heading Analysis <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn
              class="btn"
              color="primary"
              @click="downloadReport('semantic_summary')"
              :disabled="loading.semantic_summary"
            >
              <template v-if="!loading.semantic_summary">
                Semantic Analysis Summary <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn class="btn" color="primary" @click="downloadReport('descriptions')" :disabled="loading.descriptions">
              <template v-if="!loading.descriptions">
                Descriptions <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn class="btn" color="primary" @click="downloadReport('urls')" :disabled="loading.urls">
              <template v-if="!loading.urls"> URLs <v-icon dark right> mdi-export-variant</v-icon> </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn
              class="btn"
              color="primary"
              @click="downloadReport('internal_unlinked_opportunities')"
              :disabled="loading.internal_unlinked_opportunities"
            >
              <template v-if="!loading.internal_unlinked_opportunities">
                Internal Unlinked Opportunities <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn
              class="btn"
              color="primary"
              @click="downloadReport('terms_client_not_using')"
              :disabled="loading.terms_client_not_using"
            >
              <template v-if="!loading.terms_client_not_using">
                Top Terms that the Client is NOT Using <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
            <v-btn
              class="btn"
              color="primary"
              @click="downloadReport('valuable_keyword_topics')"
              :disabled="loading.valuable_keyword_topics"
            >
              <template v-if="!loading.valuable_keyword_topics">
                Valuable Keyword Topics <v-icon dark right> mdi-export-variant</v-icon>
              </template>
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    report: Object,
  },
  data() {
    return {
      dialog: false,
      format: 'csv',
      loading: {
        full_report_all_files: false,
        page_metrics: false,
        domain_link_profile: false,
        url_link_profile: false,
        backlinks_seen: false,
        domain_level_data: false,
        page_level_data: false,
        link_gaps_analysis: false,
        anchors_aggregate_text: false,
        speed_web_vitals: false,
        top_terms: false,
        how_many_times: false,
        titles: false,
        heading_analysis: false,
        semantic_summary: false,
        descriptions: false,
        urls: false,
        internal_unlinked_opportunities: false,
        terms_client_not_using: false,
        valuable_keyword_topics: false,
      },
    }
  },
  methods: {
    ...mapActions({
      exportReport: 'full_reports/exportReport',
    }),
    async downloadReport(view) {
      const data = {
        view,
        format: this.format,
        report_id: this.report.id,
      }

      this.loading[view] = true

      const resp = await this.exportReport(data)
        .catch(error => [console.log(error)])
        .finally(() => {
          this.loading[view] = false
        })

      if (resp.status === 200 && resp.data.status == 'ok') {
        let link = document.createElement('a')
        link.href = resp.data.url
        link.target = '_blank'
        link.click()
        link.remove()
      } else {
        console.log(resp)
      }
    },
  },
}
</script>

<style scoped>
.btn {
  display: block !important;
  flex: none !important;
  white-space: initial !important;
}
.format-row {
  padding: 10px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

@media (max-width: 992px) {
  .format-row {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 580px) {
  .format-row {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
