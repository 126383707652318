<template>
  <v-card class="cont-table">
    <v-card-title>
      <h3>Speed and Core Web Vitals Comparison</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="dataSort"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
      :items-per-page="-1"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('rank', index, true)" v-if="Number(comp.rank) < 100">{{
              Number(comp.rank)
            }}</span>
            <span :class="getTextClass('rank', index, true)" v-else>100+</span>
          </td>
          <td>
            <span :class="getTextClass('mobile_speed', index, true)">
              {{ Number(comp.mobile_speed).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('mobile_contentful_paint', index, true)">
              {{ Number(comp.mobile_contentful_paint).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('mobile_max_first_input_delay', index, true)">
              {{ Number(comp.mobile_max_first_input_delay).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('mobile_cumulative_layout', index, true)">
              {{ Number(comp.mobile_cumulative_layout).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('desktop_speed', index, true)">
              {{ Number(comp.desktop_speed).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('desktop_contentful_paint', index, true)">
              {{ Number(comp.desktop_contentful_paint).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('desktop_max_first_input_delay', index, true)">
              {{ Number(comp.desktop_max_first_input_delay).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('desktop_layout_shift', index, true)">
              {{ Number(comp.desktop_layout_shift).toFixed(2) }}
            </span>
          </td>
        </tr>
        <tr v-if="averages">
          <td></td>
          <td>Averages</td>
          <td>{{ averages.rank }}</td>
          <td>{{ averages.mobile_speed }}</td>
          <td>{{ averages.mobile_contentful_paint }}</td>
          <td>{{ averages.mobile_max_first_input_delay }}</td>
          <td>{{ averages.mobile_cumulative_layout }}</td>
          <td>{{ averages.desktop_speed }}</td>
          <td>{{ averages.desktop_contentful_paint }}</td>
          <td>{{ averages.desktop_max_first_input_delay }}</td>
          <td>{{ averages.desktop_layout_shift }}</td>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-checkbox v-model="selected" :ripple="false" :value="item" hide-details />
          </td>
          <td>
            <div class="col-domain">
              <a :href="item.url" :class="darkTheme ? 'url-data-light' : 'url-data-dark'" target="_blank">{{
                item.url
              }}</a>
            </div>
          </td>
          <td :style="getClassColor(item.rank_sort, item)">
            <span v-if="Number(item.rank) < 100">{{ Number(item.rank) }}</span>
            <span v-else>100+</span>
          </td>
          <td :style="getClassColor(item.mobile_speed_sort, item)">
            {{ Number(item.mobile_speed).toFixed(2) }}
          </td>
          <td :style="getClassColor(item.mobile_contentful_paint_sort, item)">
            {{ Number(item.mobile_contentful_paint).toFixed(2) }}
          </td>
          <td :style="getClassColor(item.mobile_max_first_input_delay_sort, item)">
            {{ Number(item.mobile_max_first_input_delay).toFixed(2) }}
          </td>
          <td :style="getClassColor(item.mobile_cumulative_layout_sort, item)">
            {{ Number(item.mobile_cumulative_layout).toFixed(2) }}
          </td>
          <td :style="getClassColor(item.desktop_speed_sort, item)">
            {{ Number(item.desktop_speed).toFixed(2) }}
          </td>
          <td :style="getClassColor(item.desktop_contentful_paint_sort, item)">
            {{ Number(item.desktop_contentful_paint).toFixed(2) }}
          </td>
          <td :style="getClassColor(item.desktop_max_first_input_delay_sort, item)">
            {{ Number(item.desktop_max_first_input_delay).toFixed(2) }}
          </td>
          <td :style="getClassColor(item.desktop_layout_shift_sort, item)">
            {{ Number(item.desktop_layout_shift).toFixed(2) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
  },
  data() {
    return {
      headers: [
        {
          text: 'URL',
          value: 'url',
          sortable: true,
          width: 300,
        },
        { text: 'Rank', value: 'rank', sortable: true },
        { text: 'Mobile Speed (Speed Index)', value: 'mobile_speed', sortable: true },
        { text: 'Mobile Largest Contentful Paint', value: 'mobile_contentful_paint', sortable: true },
        { text: 'Mobile Max First Input Delay', value: 'mobile_max_first_input_delay', sortable: true },
        { text: 'Mobile Cumulative Layout Shift', value: 'mobile_cumulative_layout', sortable: true },
        { text: 'Desktop Speed (Speed Index)', value: 'desktop_speed', sortable: true },
        { text: 'Desktop Largest Contentful Paint', value: 'desktop_contentful_paint', sortable: true },
        { text: 'Desktop Max First Input Delay', value: 'desktop_max_first_input_delay', sortable: true },
        { text: 'Deskop Cumulative Layout Shift', value: 'desktop_layout_shift', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
    }
  },
  computed: {
    averages: function () {
      return this.getAverages()
    },
    dataSort() {
      const arrayColumn = (arr, n) => arr.map(x => Number(x[n]))
      const sortAsc = (a, b) => a - b
      const sortDesc = (a, b) => b - a

      const rank = arrayColumn(this.items, 'rank').sort(sortDesc)
      const mobileSpeed = arrayColumn(this.items, 'mobile_speed').sort(sortDesc)
      const mobileContentfulPaint = arrayColumn(this.items, 'mobile_contentful_paint').sort(sortDesc)
      const mobileMaxFirstInputDelay = arrayColumn(this.items, 'mobile_max_first_input_delay').sort(sortDesc)
      const mobileCumulativeLayout = arrayColumn(this.items, 'mobile_cumulative_layout').sort(sortDesc)
      const desktopSpeed = arrayColumn(this.items, 'desktop_speed').sort(sortDesc)
      const desktopContentfulPaint = arrayColumn(this.items, 'desktop_contentful_paint').sort(sortDesc)
      const desktopMaxFirstInputDelay = arrayColumn(this.items, 'desktop_max_first_input_delay').sort(sortDesc)
      const desktopLayoutShift = arrayColumn(this.items, 'desktop_layout_shift').sort(sortDesc)

      return this.items.map((row, i) => {
        row.rank_sort = rank.indexOf(row.rank) + 1
        row.mobile_speed_sort = mobileSpeed.indexOf(row.mobile_speed) + 1
        row.mobile_contentful_paint_sort = mobileContentfulPaint.indexOf(row.mobile_contentful_paint) + 1
        row.mobile_max_first_input_delay_sort = mobileMaxFirstInputDelay.indexOf(row.mobile_max_first_input_delay) + 1
        row.mobile_cumulative_layout_sort = mobileCumulativeLayout.indexOf(row.mobile_cumulative_layout) + 1
        row.desktop_speed_sort = desktopSpeed.indexOf(row.desktop_speed) + 1
        row.desktop_contentful_paint_sort = desktopContentfulPaint.indexOf(row.desktop_contentful_paint) + 1
        row.desktop_max_first_input_delay_sort =
          desktopMaxFirstInputDelay.indexOf(row.desktop_max_first_input_delay) + 1
        row.desktop_layout_shift_sort = desktopLayoutShift.indexOf(row.desktop_layout_shift) + 1
        return row
      })
    },
    darkTheme() {
      return this.$vuetify.theme.isDark
    },
  },
  methods: {
    getAverages() {
      let totals = []
      const size = this.selected.length

      this.headers.forEach(row => {
        totals[row.value] = 0
      })

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = Number(totals[prop_name] / size).toFixed(2)
      }

      return totals

      // this.averages = totals;
    },
    getTextClass(name = '', index = 0, greater = false) {
      if (greater === false) {
        if (this.comparisons[index][name] >= Number(this.averages[name])) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      } else {
        if (this.comparisons[index][name] <= Number(this.averages[name])) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      }
    },
    getClassColor(value, item) {
      if (item) {
        if (this.selected.indexOf(item) < 0) {
          return null
        }
      }
      if (value) {
        return `background: rgb(52, 144, 220, ${value / 10})`
      }
      return null
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 60px 0px 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
.url-data-light {
  color: var(--primary-shade--dark);
  text-decoration: none;
}
.url-data-dark {
  color: var(--primary-shade--light);
  text-decoration: none;
}
</style>
