<template>
  <v-card class="cont-table">
    <v-card-title>
      <h3>Anchor Aggregate Text Report</h3>
    </v-card-title>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(row, index) in anchors" :key="index">
        <v-expansion-panel-header>
          <v-row>
            <div class="item-option">
              <v-avatar color="info" size="20"> {{ index + 1 }} </v-avatar>
              <span class="item-title">{{ row.url }}</span>
            </div>
            <div class="chart-container">
              <div class="chart-item" v-if="row.values[0]">
                <h3 class="text-center">All Anchors</h3>
                <pie-chart-donut
                  style="max-width: 500px"
                  :series="allAnchors"
                  :displays="['Exact Match', 'Partial Match', 'Branded', 'Naked URL', 'Generic']"
                ></pie-chart-donut>
              </div>
              <div class="chart-item" v-if="row.values[0]">
                <h3 class="text-center">Comparison anchor</h3>
                <pie-chart-donut
                  style="max-width: 500px"
                  :series="getCategories(row)"
                  :displays="['Exact Match', 'Partial Match', 'Branded', 'Naked URL', 'Generic']"
                ></pie-chart-donut>
              </div>
            </div>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <anchor-text-item :loading="loading" :headers="headers" :items="row.values" :allAnchors="allAnchors" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import PieChartDonut from '@/components/chart/apex-chart/PieChartDonut.vue'
import AnchorTextItem from './AnchorTextItem.vue'
export default {
  components: { AnchorTextItem, PieChartDonut },
  props: {
    loading: Boolean,
    items: Array,
    allItems: Object,
    keyword: String,
  },
  data() {
    return {
      headers: [
        { text: 'Anchors', value: 'anchor', sortable: true, width: 300 },
        { text: 'Referring domains', value: 'count', sortable: true },
        { text: 'Category', value: 'category', sortable: true },
        { text: 'Percentage', value: 'percentage', sortable: true },
        { text: 'Chart', value: 'chart', sortable: true },
      ],
      anchors: [],
      categories: [0, 0, 0, 0, 0],
    }
  },
  mounted() {
    this.getAnchors()
  },
  computed: {
    allAnchors() {
      return this.getAllCategories()
    },
  },
  methods: {
    getCategories(row) {
      let anchor_categories = {
        exact: 0,
        partial: 0,
        branded: 0,
        naked_URL: 0,
        generic: 0,
      }

      row.values.forEach(element => {
        if (element.category == 'Exact Match') {
          anchor_categories.exact = anchor_categories.exact + 1
        } else if (element.category == 'Partial Match') {
          anchor_categories.partial++
        } else if (element.category == 'Branded') {
          anchor_categories.branded++
        } else if (element.category == 'Naked URL') {
          anchor_categories.naked_URL++
        } else if (element.category == 'Generic') {
          anchor_categories.generic++
        }
      })

      return [
        anchor_categories.exact,
        anchor_categories.partial,
        anchor_categories.branded,
        anchor_categories.naked_URL,
        anchor_categories.generic,
      ]
    },

    getAnchors() {
      let list = []
      let list_items = []

      this.items.forEach(el => {
        if (el.values) {
          list_items = []

          for (const key in el.values) {
            const row = {
              anchor: el.values[key].anchor,
              count: el.values[key].count,
              percentage: (el.values[key].count / el.total) * 100,
              chart: (el.values[key].count / el.total) * 100,
              category: el.values[key].category,
            }
            list_items.push(row)
          }
        }

        const value = {
          values: list_items,
          url: el.url,
          total: el.total,
          is_client: el.is_client,
        }
        list.push(value)
      })

      this.anchors = list
    },

    getCategory(anchor, url) {
      if (this.keyword == anchor) return 'Exact Match'
      if (this.keyword.toUpperCase() == anchor.toUpperCase()) return 'Partial Match'
      if (anchor.includes(this.keyword) && !anchor.includes('.')) return 'Partial Match'
      const newUrl = new URL(url)
      if (newUrl.hostname.includes(anchor) && anchor != '') return 'Branded'
      if (anchor.includes('www.') || anchor.includes('http://') || anchor.includes('https://') || anchor.includes('.'))
        return 'Naked URL'
      if (
        anchor.toUpperCase() == 'GO NOW' ||
        anchor.toUpperCase() == 'CLICK HERE' ||
        anchor.toUpperCase() == 'HERE' ||
        anchor == ''
      )
        return 'Generic'
      return 'N/A'
    },

    getAllCategories() {
      let anchor_categories = {
        exact: 0,
        partial: 0,
        branded: 0,
        naked_URL: 0,
        generic: 0,
      }

      this.allItems.values.forEach(element => {
        if (element.category == 'Exact Match') {
          anchor_categories.exact = anchor_categories.exact + 1
        } else if (element.category == 'Partial Match') {
          anchor_categories.partial++
        } else if (element.category == 'Branded') {
          anchor_categories.branded++
        } else if (element.category == 'Naked URL') {
          anchor_categories.naked_URL++
        } else if (element.category == 'Generic') {
          anchor_categories.generic++
        }
      })
      return [
        anchor_categories.exact,
        anchor_categories.partial,
        anchor_categories.branded,
        anchor_categories.naked_URL,
        anchor_categories.generic,
      ]
    },
  },
}
</script>

<style scoped>
.item-option {
  display: flex;
  flex-direction: row;
  gap: 10px;
  word-break: break-all;
}
.item-title {
  color: #2ecc71;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}

.chart-container {
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.chart-container .chart-item {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  /* background: red; */
}
</style>
