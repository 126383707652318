<template>
  <div>
    <template v-if="data.items_suggestions">
      <action-items-consider
        v-if="data.items_suggestions.length > 0"
        :loading="loading"
        :items="data.items_suggestions"
      />
    </template>

    <template v-if="data.urls_compare">
      <page-metrics :loading="loading" :items="data.urls" :comparisons="data.urls_compare" :avgs="data.avgs" />
    </template>

    <template v-if="data.urls_compare">
      <domain-link-profile :loading="loading" :items="data.urls" :comparisons="data.urls_compare" :avgs="data.avgs" />
    </template>

    <template v-if="data.urls_compare">
      <url-link-profile :loading="loading" :items="data.urls" :comparisons="data.urls_compare" :avgs="data.avgs" />
    </template>

    <template v-if="data.web_vitals">
      <speed-web-vitals-table :loading="loading" :items="data.web_vitals" :comparisons="data.web_vitals_comp" />
    </template>

    <template v-if="data.backlinks_seen">
      <backlinks-seen
        :loading="loading"
        :items="data.backlinks_seen"
        :comparisons="data.backlinks_seen_comp"
        :avgs="data.backlinks_seen_avgs"
      />
    </template>

    <template v-if="data.backlinks_seen">
      <chart-back-seen
        :loading="loading"
        :items="data.backlinks_seen"
        :comparisons="data.backlinks_seen_comp"
        :avgs="data.backlinks_seen_avgs"
      />
    </template>

    <template v-if="data.domain_metrics">
      <domain-level-data-1
        :loading="loading"
        :items="data.domain_metrics"
        :comparisons="data.domain_metrics_comp"
        :avgs="data.domain_metrics_avgs"
      />
    </template>
    <template v-if="data.domain_metrics">
      <domain-level-data-2
        :loading="loading"
        :items="data.domain_metrics"
        :comparisons="data.domain_metrics_comp"
        :avgs="data.domain_metrics_avgs"
      />
    </template>

    <template v-if="data.page_metrics">
      <page-level-data-1
        :loading="loading"
        :items="data.page_metrics"
        :comparisons="data.page_metrics_comp"
        :avgs="data.page_metrics_avgs"
      />
    </template>
    <template v-if="data.page_metrics">
      <page-level-data-2
        :loading="loading"
        :items="data.page_metrics"
        :comparisons="data.page_metrics_comp"
        :avgs="data.page_metrics_avgs"
      />
    </template>

    <template v-if="data.link_gaps_analysis">
      <link-gaps-analysis :loading="loading" :items="data.link_gaps_analysis" />
    </template>

    <template v-if="data.backlinks_ur_dr">
      <ur-and-dr
        :loading="loading"
        :items="data.backlinks_ur_dr"
        :comparisons="data.backlinks_ur_dr_comp"
        :avgs="data.avg_ur_dr"
      />
    </template>

    <template v-if="data.anchor_all">
      <anchor-text-all :loading="loading" :items="data.anchor_all" />
    </template>

    <template v-if="data.anchor_analisys">
      <anchor-text-list
        :loading="loading"
        :items="data.anchor_analisys"
        :keyword="data.report.keyword"
        :allItems="data.anchor_all"
      />
    </template>

    <template> </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
//components
import ActionItemsConsider from './ActionItemsConsider.vue'
import PageMetrics from './PageMetrics.vue'
import DomainLinkProfile from './DomainLinkProfile.vue'
import UrlLinkProfile from './UrlLinkProfile.vue'
import BacklinksSeen from './BacklinksSeen.vue'
import ChartBackSeen from './ChartBackSeen.vue'
import DomainLevelData1 from './DomainLevelData1.vue'
import DomainLevelData2 from './DomainLevelData2.vue'
import PageLevelData1 from './PageLevelData1.vue'
import PageLevelData2 from './PageLevelData2.vue'
import LinkGapsAnalysis from './LinkGapsAnalysis.vue'
import UrAndDr from './UrAndDr.vue'
import AnchorTextList from './AnchorTextList.vue'
import AnchorTextAll from './AnchorTextAll.vue'
import SpeedWebVitalsTable from './SpeedWebVitalsTable.vue'
import PieChartDonut from '@/components/chart/apex-chart/PieChartDonut.vue'

export default {
  components: {
    PageMetrics,
    DomainLinkProfile,
    UrlLinkProfile,
    BacklinksSeen,
    ChartBackSeen,
    DomainLevelData1,
    DomainLevelData2,
    PageLevelData1,
    PageLevelData2,
    LinkGapsAnalysis,
    UrAndDr,
    AnchorTextList,
    AnchorTextAll,
    ActionItemsConsider,
    SpeedWebVitalsTable,
    PieChartDonut,
  },
  props: {
    loading: Boolean,
    data: Object,
  },
  data() {
    return {}
  },
}
</script>
