<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <v-container v-else class="d-flex flex-column">
    <no-found-report v-if="!!errors" :errors="errors" />

    <template v-else>
      <h1>Full Report</h1>
      <div class="header-row">
        <div class="header-report">
          <div class="row-text">
            <span> Report Name: {{ report ? report.report_name : '' }} </span>
          </div>
          <div class="row-text">
            <span
              >Keyword: <a :href="googleLink(report.keyword)" target="_blank">{{ report.keyword }}</a>
            </span>
          </div>
          <div class="row-text">
            <div class="col-comparison">
              <span v-for="(url, index) in report.comp_urls" :key="index">
                Comparison URL: {{ index == 0 ? '' : index + 1 }}
                <a :href="externalLink(url)" target="_blank">{{ url }}</a>
              </span>
            </div>
          </div>
        </div>

        <div class="col-actions text-center">
          <dialog-export :report="report" />
          <dialog-share-report v-if="report && report.id" :report="report" report_type="full_report" />
        </div>
      </div>
    </template>

    <serp-all-components v-if="serp_data" :loading="loading" :data="serp_data" />
    <semantic-all-components v-if="semantic_data" :loading="loading" :values="semantic_data" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SemanticAllComponents from '../semantic-analysis/components/SemanticAllComponents.vue'
import SerpAllComponents from '../serp-analysis/components/SerpAllComponents.vue'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import DialogExport from './components/DialogExport.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue'
//components
import NoFoundReport from '@/components/report/NoFoundReport.vue'

export default {
  components: {
    SerpAllComponents,
    SemanticAllComponents,
    ViewLoader,
    DialogExport,
    DialogShareReport,
    NoFoundReport,
  },
  data() {
    return {
      loading: true,
      errors: null,

      serp_data: null,
      semantic_data: null,
      report: {},
    }
  },
  mounted() {
    this.getReportData()
  },
  methods: {
    ...mapActions({
      getReport: 'full_reports/reportData',
      setSnackbar: 'snackbar/set',
    }),
    async getReportData() {
      this.loading = true
      await this.getReport(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            const { serp_data, semantic_data, report } = resp.data
            this.serp_data = serp_data
            this.semantic_data = semantic_data
            this.report = report
            this.errors = null
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
          this.setSnackbar({
            status: true,
            text: 'No report found.',
            color: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
    externalLink(url) {
      if (url.includes('https://') || url.includes('http://')) {
        return url
      } else {
        return 'https://' + url
      }
    },
  },
}
</script>

<style scoped>
.header-report {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.col-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
