import { render, staticRenderFns } from "./ActionItemsConsider.vue?vue&type=template&id=2564ff3e&scoped=true&"
import script from "./ActionItemsConsider.vue?vue&type=script&lang=js&"
export * from "./ActionItemsConsider.vue?vue&type=script&lang=js&"
import style0 from "./ActionItemsConsider.vue?vue&type=style&index=0&id=2564ff3e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2564ff3e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardTitle,VChip,VDataTable,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VSpacer,VTextField})
