<template>
  <div id="chart">
    <vue-apex-charts type="donut" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: ['series', 'displays'],
  data() {
    return {
      //series:  [10, 41, 35, 51,65],
      chartOptions: {
        labels: this.displays,
        chart: {
          type: 'donut',
        },
        responsive: [
          {
            breakpoint: 20,
            options: {
              chart: {
                width: 200,
                redrawOnParentResize: true,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    }
  },
}
</script>

<style>
</style>
