var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cont-table"},[_c('v-card-title',[_c('h3',[_vm._v("Action Items to Consider")])]),_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((_vm.items),function(comp,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('div',{staticClass:"item-option"},[_c('span',{attrs:{"color":"info","size":"20"}},[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"secondary","outlined":""}},[_vm._v(_vm._s('comparison')+" ")])],1),_c('span',{staticClass:"item-title"},[_vm._v(_vm._s(comp.url))])])]),_c('v-expansion-panel-content',[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":comp.items,"loading":_vm.loading,"search":_vm.search,"item-key":"url","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.table",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.table)+" ")]}},{key:"item.metric",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.metric)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-error"},[_vm._v(_vm._s(item.value))])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message)+" ")]}}],null,true)})],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }