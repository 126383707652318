<template>
  <v-card class="cont-table">
    <v-card-title>
      <h3>Backlinks Seen</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="dataSort"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
      :items-per-page="-1"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td>{{ 'comparison' }}</td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('last_week', index)">
              {{ Number(comp.last_week) }}
            </span>
          </td>

          <td>
            <span :class="getTextClass('months_ago_1', index)">{{ Number(comp.months_ago_1) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_2', index)">{{ Number(comp.months_ago_2) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_3', index)">{{ Number(comp.months_ago_3) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_4', index)">{{ Number(comp.months_ago_4) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_5', index)">{{ Number(comp.months_ago_5) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_6', index)">{{ Number(comp.months_ago_6) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_7', index)">{{ Number(comp.months_ago_7) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_8', index)">{{ Number(comp.months_ago_8) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_9', index)">{{ Number(comp.months_ago_9) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_10', index)">{{ Number(comp.months_ago_10) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_11', index)">{{ Number(comp.months_ago_11) }} </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_12', index)">{{ Number(comp.months_ago_12) }} </span>
          </td>

          <td>
            <span :class="getTextClass('last_month', index)">
              {{ Number(comp.last_month) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('last_3_months', index)"> {{ Number(comp.last_3_months) }} </span>
          </td>
          <td>
            <span :class="getTextClass('last_6_months', index)">{{ Number(comp.last_6_months) }} </span>
          </td>
          <td>
            <span :class="getTextClass('last_12_months', index)">{{ Number(comp.last_12_months) }} </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>Averages</td>
          <td>{{ Number(averages.last_week).toFixed(2) }}</td>

          <td>{{ Number(averages.months_ago_1).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_2).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_3).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_4).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_5).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_6).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_7).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_8).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_9).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_10).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_11).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_12).toFixed(2) }}</td>

          <td>{{ Number(averages.last_month).toFixed(2) }}</td>
          <td>{{ Number(averages.last_3_months).toFixed(2) }}</td>
          <td>{{ Number(averages.last_6_months).toFixed(2) }}</td>
          <td>{{ Number(averages.last_12_months).toFixed(2) }}</td>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-checkbox v-model="selected" :ripple="false" :value="item" hide-details />
          </td>
          <td>{{ item.position }}</td>
          <td>
            <div class="col-domain">
              <a :href="item.url" :class="darkTheme ? 'url-data-light' : 'url-data-dark'" target="_blank">{{
                item.url
              }}</a>
            </div>
          </td>
          <td :style="getClassColor(item.last_week_sort, item)">
            {{ Number(item.last_week) }}
          </td>

          <td :style="getClassColor(item.months_ago_1_sort, item)">
            {{ Number(item.months_ago_1) }}
          </td>
          <td :style="getClassColor(item.months_ago_2_sort, item)">
            {{ Number(item.months_ago_2) }}
          </td>
          <td :style="getClassColor(item.months_ago_3_sort, item)">
            {{ Number(item.months_ago_3) }}
          </td>
          <td :style="getClassColor(item.months_ago_4_sort, item)">
            {{ Number(item.months_ago_4) }}
          </td>
          <td :style="getClassColor(item.months_ago_5_sort, item)">
            {{ Number(item.months_ago_5) }}
          </td>
          <td :style="getClassColor(item.months_ago_6_sort, item)">
            {{ Number(item.months_ago_6) }}
          </td>
          <td :style="getClassColor(item.months_ago_7_sort, item)">
            {{ Number(item.months_ago_7) }}
          </td>
          <td :style="getClassColor(item.months_ago_8_sort, item)">
            {{ Number(item.months_ago_8) }}
          </td>
          <td :style="getClassColor(item.months_ago_9_sort, item)">
            {{ Number(item.months_ago_9) }}
          </td>
          <td :style="getClassColor(item.months_ago_10_sort, item)">
            {{ Number(item.months_ago_10) }}
          </td>
          <td :style="getClassColor(item.months_ago_11_sort, item)">
            {{ Number(item.months_ago_11) }}
          </td>
          <td :style="getClassColor(item.months_ago_12_sort, item)">
            {{ Number(item.months_ago_12) }}
          </td>

          <td :style="getClassColor(item.last_month_sort, item)">
            {{ Number(item.last_month) }}
          </td>
          <td :style="getClassColor(item.last_3_months_sort, item)">
            {{ Number(item.last_3_months) }}
          </td>
          <td :style="getClassColor(item.last_6_months_sort, item)">
            {{ Number(item.last_6_months) }}
          </td>
          <td :style="getClassColor(item.last_12_months_sort, item)">
            {{ Number(item.last_12_months) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      headers: [
        { text: 'Position', value: 'position', sortable: true },
        { text: 'URL', value: 'url', sortable: true, width: 350 },
        { text: 'Last Week', value: 'last_week', sortable: true, width: 100 },
        { text: '1 Month Ago', value: 'months_ago_1', sortable: true, width: 100 },
        { text: '2 Month Ago', value: 'months_ago_2', sortable: true, width: 100 },
        { text: '3 Month Ago', value: 'months_ago_3', sortable: true, width: 100 },
        { text: '4 Month Ago', value: 'months_ago_4', sortable: true, width: 100 },
        { text: '5 Month Ago', value: 'months_ago_5', sortable: true, width: 100 },
        { text: '6 Month Ago', value: 'months_ago_6', sortable: true, width: 100 },
        { text: '7 Month Ago', value: 'months_ago_7', sortable: true, width: 100 },
        { text: '8 Month Ago', value: 'months_ago_8', sortable: true, width: 100 },
        { text: '9 Month Ago', value: 'months_ago_9', sortable: true, width: 100 },
        { text: '10 Month Ago', value: 'months_ago_10', sortable: true, width: 100 },
        { text: '11 Month Ago', value: 'months_ago_11', sortable: true, width: 100 },
        { text: '12 Month Ago', value: 'months_ago_12', sortable: true, width: 100 },
        { text: 'Last Month', value: 'last_month', sortable: true, width: 100 },
        { text: 'Last 3 Months', value: 'last_3_months', sortable: true, width: 100 },
        { text: 'Last 6 Months', value: 'last_6_months', sortable: true, width: 100 },
        { text: 'Last 12 Month', value: 'last_12_months', sortable: true, width: 100 },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.getAverages()
      },
    },
  },
  computed: {
    dataSort() {
      const arrayColumn = (arr, n) => arr.map(x => Number(x[n]))
      const sortAsc = (a, b) => a - b
      const sortDesc = (a, b) => b - a

      const position = arrayColumn(this.items, 'position').sort(sortAsc)
      const lastWeek = arrayColumn(this.items, 'last_week').sort(sortAsc)
      const lastMonth = arrayColumn(this.items, 'last_month').sort(sortAsc)
      const last3Months = arrayColumn(this.items, 'last_3_months').sort(sortAsc)
      const last6Months = arrayColumn(this.items, 'last_6_months').sort(sortAsc)
      const last12Months = arrayColumn(this.items, 'last_12_months').sort(sortAsc)
      const monthsAgo1 = arrayColumn(this.items, 'months_ago_1').sort(sortAsc)
      const monthsAgo2 = arrayColumn(this.items, 'months_ago_2').sort(sortAsc)
      const monthsAgo3 = arrayColumn(this.items, 'months_ago_3').sort(sortAsc)
      const monthsAgo4 = arrayColumn(this.items, 'months_ago_4').sort(sortAsc)
      const monthsAgo5 = arrayColumn(this.items, 'months_ago_5').sort(sortAsc)
      const monthsAgo6 = arrayColumn(this.items, 'months_ago_6').sort(sortAsc)
      const monthsAgo7 = arrayColumn(this.items, 'months_ago_7').sort(sortAsc)
      const monthsAgo8 = arrayColumn(this.items, 'months_ago_8').sort(sortAsc)
      const monthsAgo9 = arrayColumn(this.items, 'months_ago_9').sort(sortAsc)
      const monthsAgo10 = arrayColumn(this.items, 'months_ago_10').sort(sortAsc)
      const monthsAgo11 = arrayColumn(this.items, 'months_ago_11').sort(sortAsc)
      const monthsAgo12 = arrayColumn(this.items, 'months_ago_12').sort(sortAsc)

      return this.items.map((row, i) => {
        row.position_sort = position.indexOf(row.position) + 1
        row.last_week_sort = lastWeek.indexOf(row.last_week) + 1
        row.last_month_sort = lastMonth.indexOf(row.last_month) + 1
        row.last_3_months_sort = last3Months.indexOf(row.last_3_months) + 1
        row.last_6_months_sort = last6Months.indexOf(row.last_6_months) + 1
        row.last_12_months_sort = last12Months.indexOf(row.last_12_months) + 1
        row.months_ago_1_sort = monthsAgo1.indexOf(row.months_ago_1) + 1
        row.months_ago_2_sort = monthsAgo2.indexOf(row.months_ago_2) + 1
        row.months_ago_3_sort = monthsAgo3.indexOf(row.months_ago_3) + 1
        row.months_ago_4_sort = monthsAgo4.indexOf(row.months_ago_4) + 1
        row.months_ago_5_sort = monthsAgo5.indexOf(row.months_ago_5) + 1
        row.months_ago_6_sort = monthsAgo6.indexOf(row.months_ago_6) + 1
        row.months_ago_7_sort = monthsAgo7.indexOf(row.months_ago_7) + 1
        row.months_ago_8_sort = monthsAgo8.indexOf(row.months_ago_8) + 1
        row.months_ago_9_sort = monthsAgo9.indexOf(row.months_ago_9) + 1
        row.months_ago_10_sort = monthsAgo10.indexOf(row.months_ago_10) + 1
        row.months_ago_11_sort = monthsAgo11.indexOf(row.months_ago_11) + 1
        row.months_ago_12_sort = monthsAgo12.indexOf(row.months_ago_12) + 1
        return row
      })
    },
    darkTheme() {
      return this.$vuetify.theme.isDark
    },
  },
  methods: {
    getAverages() {
      let totals = this.averages
      const size = this.selected.length

      for (const prop_name in this.averages) {
        totals[prop_name] = 0
      }

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = totals[prop_name] / size
      }

      this.averages = totals
    },
    getTextClass(name = '', index = 0, greater = false) {
      if (greater === false) {
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      } else {
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      }
    },
    getClassColor(value, item) {
      if (item) {
        if (this.selected.indexOf(item) < 0) {
          return null
        }
      }
      if (value) {
        return `background: rgb(52, 144, 220, ${value / 10})`
      }
      return null
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
.url-data-light {
  color: var(--primary-shade--dark);
  text-decoration: none;
}
.url-data-dark {
  color: var(--primary-shade--light);
  text-decoration: none;
}
</style>
