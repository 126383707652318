<template>
  <v-card>
    <v-card-text>
      <vue-apex-charts width="100%" height="500" type="line" :options="options" :series="series" />
    </v-card-text>
  </v-card>
</template>

<script>
import { serpSchema, clientColor } from '@/components/chart/chartjs/colors_schema'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      urls: [],
      datacollection: null,
      /* options: {
        responsive: true,
        legend: {
          // display: false,
        },
      }, */
      options: {
        colors: serpSchema,
        stroke: {
          curve: 'smooth',
        },
        chart: {
          height: 500,
          width: '100%',
          type: 'area',
        },
        xaxis: {
          categories: [
            '1 mo',
            '2 mo',
            '3 mo',
            '4 mo',
            '5 mo',
            '6 mo',
            '7 mo',
            '8 mo',
            '9 mo',
            '10 mo',
            '11 mo',
            '12 mo',
          ],
        },
      },
    }
  },
  mounted() {
    this.getUrls()
  },
  computed: {
    series() {
      const dataItems = [...this.items, ...this.comparisons]

      return dataItems.map((row, index) => {
        const url = new URL(row.url)
        return {
          name: url.hostname,
          data: [
            row.months_ago_1,
            row.months_ago_2,
            row.months_ago_3,
            row.months_ago_4,
            row.months_ago_5,
            row.months_ago_6,
            row.months_ago_7,
            row.months_ago_8,
            row.months_ago_9,
            row.months_ago_10,
            row.months_ago_11,
            row.months_ago_12,
          ],
          fill: false,
        }
      })
    },
  },
  methods: {
    getUrls() {
      this.urls = this.items.map(row => row.url)
    },
  },
}
</script>

