<template>
  <div>
    <v-card >
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="dataSort"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
      :items-per-page="-1"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td> {{ 'comparison' }} </td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('backlinks', index)">
              {{ Number(comp.dofollow).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('text', index)">
              {{ Number(comp.nofollow).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('image', index)">
              {{ Number(comp.canonical).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('ugc', index)">
              {{ Number(comp.gov).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('sponsored', index)">
              {{ Number(comp.edu).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('redirect', index)">
              {{ Number(comp.rss).toFixed(0) }}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>Averages</td>
          <td>{{ Number(averages.dofollow).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.nofollow).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.canonical).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.gov).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.edu).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
          <td>{{ Number(averages.rss).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
        </tr>
      </template>

      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-checkbox v-model="selected" :ripple="false" :value="item" hide-details />
          </td>
          <td>
          {{ Number(item.rank).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td>
            <div class="col-domain">
              <a :href="item.url" :class="darkTheme ? 'url-data-light' : 'url-data-dark'" target="_blank">{{ item.url }}</a>
            </div>
          </td>
          <td :style="getClassColor(item.dofollow_sort, item)">
            {{ Number(item.dofollow).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.nofollow_sort, item)">
            {{ Number(item.nofollow).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.canonical_sort, item)">
            {{ Number(item.canonical).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.gov_sort, item)">
            {{ Number(item.gov).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.edu_sort, item)">
            {{ Number(item.edu).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          <td :style="getClassColor(item.rss_sort, item)">
            {{ Number(item.rss).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </td>
          </tr>
        </template>

    </v-data-table>
  </v-card>

  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      headers: [
        { text: 'Rank', value: 'rank', sortable: true },
        { text: 'URL', value: 'url', sortable: true, width: 350 },
        { text: 'Dofollow', value: 'dofollow', sortable: true },
        { text: 'Nofollow', value: 'nofollow', sortable: true },
        { text: 'Canonical', value: 'canonical', sortable: true },
        { text: 'Gov', value: 'gov', sortable: true },
        { text: 'Edu', value: 'edu', sortable: true },
        { text: 'Rss', value: 'rss', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.getAverages()
      },
    },
  },
  computed: {
    dataSort() {
      const arrayColumn = (arr, n) => arr.map(x => Number(x[n]))
      const sortAsc = (a, b) => a - b
      const sortDesc = (a, b) => b - a

      const rank = arrayColumn(this.items, 'rank').sort(sortDesc)
      const dofollow = arrayColumn(this.items, 'dofollow').sort(sortAsc)
      const nofollow = arrayColumn(this.items, 'nofollow').sort(sortAsc)
      const canonical = arrayColumn(this.items, 'canonical').sort(sortAsc)
      const gov = arrayColumn(this.items, 'gov').sort(sortAsc)
      const edu = arrayColumn(this.items, 'edu').sort(sortAsc)
      const rss = arrayColumn(this.items, 'rss').sort(sortAsc)

      return this.items.map((row, i) => {
        row.rank_sort = rank.indexOf(row.rank) + 1
        row.dofollow_sort = dofollow.indexOf(row.dofollow) + 1
        row.nofollow_sort = nofollow.indexOf(row.nofollow) + 1
        row.canonical_sort = canonical.indexOf(row.canonical) + 1
        row.gov_sort = gov.indexOf(row.gov) + 1
        row.edu_sort = edu.indexOf(row.edu) + 1
        row.rss_sort = rss.indexOf(row.rss) + 1
        return row
      })
    },
    darkTheme() {
      return this.$vuetify.theme.isDark;
    }
  },
  methods: {
    getAverages() {
      let totals = this.averages
      const size = this.selected.length

      for (const prop_name in this.averages) {
        totals[prop_name] = 0
      }

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = totals[prop_name] / size
      }

      this.averages = totals
    },
    getTextClass(name = '', index = 0, greater = false) {
      if (greater === false) {
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      } else {
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      }
    },
    getClassColor(value, item) {
      if (item) {
        if (this.selected.indexOf(item) < 0) {
          return null
        }
      }
      if (value) {
        return `background: rgb(52, 144, 220, ${value / 10})`
      }
      return null
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
.url-data-light{
  color : var(--primary-shade--dark);
  text-decoration: none;
}
.url-data-dark{
  color : var(--primary-shade--light);
  text-decoration: none;
}
</style>
